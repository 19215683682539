body {
  overflow-x: hidden;
}
.btn {
  border: 0;
  outline: none;
  border-radius: 0;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  background: #1a6db1;
  color: #ffffff;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
  -webkit-appearance: none;
}
.btn.btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
}
.btn-block {
  display: block;
  width: 100%;
}
html {
  height: 100%;
  background: -moz-radial-gradient(bottom ellipse, #e6e6e6 0%, #e6e6e6 100%) !important;
  background: radial-gradient(ellipse at bottom, #e6e6e6 0%, #e6e6e6 100%) !important;
}
#loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}
#loader {
  display: block;
  width: 100px;
  height: 100px;
  margin: 0 auto;
  position: relative;
  top: 40%;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  z-index: 1001;
  -webkit-animation: spin 2s linear infinite;
  -moz-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
#loader:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  -webkit-animation: spin 3s linear infinite;
     -moz-animation: spin 3s linear infinite;
          animation: spin 3s linear infinite;
}
#loader:after {
  content: "";
  position: absolute;
  top: 15px;
  right: 15px;
  left: 15px;
  bottom: 15px;
  border-radius: 50%;
}
#loader-wrapper .loader-section {
  position: fixed;
  top: 0;
  width: 51%;
  height: 100%;
  background-color: lightgray;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-blend-mode: multiply;
  z-index: 1000;
  -webkit-transform: translateX(0);
     -moz-transform: translateX(0);
      -ms-transform: translateX(0);
          transform: translateX(0);
}
#loader-wrapper .loader-section.section-left {
  left: 0;
}
#loader-wrapper .loader-section.section-right {
  right: 0;
}
.circ-one {
  position: relative;
  display: block;
  width: 50px;
  height: 50px;
  background: #d95b43;
  border-radius: 100%;
  float: left;
  -webkit-animation: load-x 1s cubic-bezier(0.445, 0.1, 0.55, 0.9) infinite;
     -moz-animation: load-x 1s cubic-bezier(0.445, 0.1, 0.55, 0.9) infinite;
          animation: load-x 1s cubic-bezier(0.445, 0.1, 0.55, 0.9) infinite;
}
.circ-two {
  position: relative;
  display: block;
  width: 50px;
  height: 50px;
  background: #542437;
  border-radius: 100%;
  float: right;
  -webkit-animation: load-y 1s cubic-bezier(0.445, 0.1, 0.55, 0.9) infinite;
     -moz-animation: load-y 1s cubic-bezier(0.445, 0.1, 0.55, 0.9) infinite;
          animation: load-y 1s cubic-bezier(0.445, 0.1, 0.55, 0.9) infinite;
}
@-webkit-keyframes load-x {
  0% {
    left: -10px;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  25% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    z-index: 2;
  }
  50% {
    left: 60px;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  75% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    z-index: 1;
  }
  100% {
    left: -10px;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-moz-keyframes load-x {
  0% {
    left: -10px;
    -moz-transform: scale(1);
         transform: scale(1);
  }
  25% {
    -moz-transform: scale(1.5);
         transform: scale(1.5);
    z-index: 2;
  }
  50% {
    left: 60px;
    -moz-transform: scale(1);
         transform: scale(1);
  }
  75% {
    -moz-transform: scale(0.5);
         transform: scale(0.5);
    z-index: 1;
  }
  100% {
    left: -10px;
    -moz-transform: scale(1);
         transform: scale(1);
  }
}
@keyframes load-x {
  0% {
    left: -10px;
    -webkit-transform: scale(1);
       -moz-transform: scale(1);
            transform: scale(1);
  }
  25% {
    -webkit-transform: scale(1.5);
       -moz-transform: scale(1.5);
            transform: scale(1.5);
    z-index: 2;
  }
  50% {
    left: 60px;
    -webkit-transform: scale(1);
       -moz-transform: scale(1);
            transform: scale(1);
  }
  75% {
    -webkit-transform: scale(0.5);
       -moz-transform: scale(0.5);
            transform: scale(0.5);
    z-index: 1;
  }
  100% {
    left: -10px;
    -webkit-transform: scale(1);
       -moz-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes load-y {
  0% {
    right: -10px;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  25% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  50% {
    right: 60px;
    -webkit-transform: scale(1);
            transform: scale(1);
    z-index: 1;
  }
  75% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    z-index: 2;
  }
  100% {
    right: -10px;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-moz-keyframes load-y {
  0% {
    right: -10px;
    -moz-transform: scale(1);
         transform: scale(1);
  }
  25% {
    -moz-transform: scale(0.5);
         transform: scale(0.5);
  }
  50% {
    right: 60px;
    -moz-transform: scale(1);
         transform: scale(1);
    z-index: 1;
  }
  75% {
    -moz-transform: scale(1.5);
         transform: scale(1.5);
    z-index: 2;
  }
  100% {
    right: -10px;
    -moz-transform: scale(1);
         transform: scale(1);
  }
}
@keyframes load-y {
  0% {
    right: -10px;
    -webkit-transform: scale(1);
       -moz-transform: scale(1);
            transform: scale(1);
  }
  25% {
    -webkit-transform: scale(0.5);
       -moz-transform: scale(0.5);
            transform: scale(0.5);
  }
  50% {
    right: 60px;
    -webkit-transform: scale(1);
       -moz-transform: scale(1);
            transform: scale(1);
    z-index: 1;
  }
  75% {
    -webkit-transform: scale(1.5);
       -moz-transform: scale(1.5);
            transform: scale(1.5);
    z-index: 2;
  }
  100% {
    right: -10px;
    -webkit-transform: scale(1);
       -moz-transform: scale(1);
            transform: scale(1);
  }
}
#loader p {
  text-align: center;
  font-weight: 50;
  margin-bottom: 25px;
  color: #c02942;
  -webkit-animation: pulse 2s ease-in-out infinite;
     -moz-animation: pulse 2s ease-in-out infinite;
          animation: pulse 2s ease-in-out infinite;
}
@-webkit-keyframes pulse {
  50% {
    opacity: 0.5;
  }
}
@-moz-keyframes pulse {
  50% {
    opacity: 0.5;
  }
}
@keyframes pulse {
  50% {
    opacity: 0.5;
  }
}
.loaded #loader-wrapper .loader-section.section-left {
  -webkit-transform: translateY(-100%);
     -moz-transform: translateY(-100%);
      -ms-transform: translateY(-100%);
          transform: translateY(-100%);
  -moz-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.loaded #loader-wrapper .loader-section.section-right {
  -webkit-transform: translateY(100%);
     -moz-transform: translateY(100%);
      -ms-transform: translateY(100%);
          transform: translateY(100%);
  -moz-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.loaded #loader {
  opacity: 0;
  -moz-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.loaded #loader-wrapper {
  visibility: hidden;
  -webkit-transform: translateX(-100%);
     -moz-transform: translateX(-100%);
      -ms-transform: translateX(-100%);
          transform: translateX(-100%);
  -moz-transition: all 0.3s 1s ease-out;
  transition: all 0.3s 1s ease-out;
}
body {
  font-family: 'Open Sans', sans-serif;
  background: 0 0!important;
  height: 700px;
}
a {
  text-decoration: none;
  color: #1ab188;
  -moz-transition: 0.5s ease;
  transition: 0.5s ease;
}
.form-container {
  height: 100%;
  display: none;
}
.form-container.active {
  display: block;
}
h5 {
  text-align: center;
}
.container {
  height: 100%;
}
.header {
  height: 84px;
  background-color: #e6e6e6;
}
.header .col-xs-12 {
  width: 100%;
  padding: 10px;
}
.header .col-xs-12 .title1 {
  color: white;
  display: block;
  text-align: center;
  font-size: 20px;
  font-family: lato, sans-serif;
  font-weight: 700;
}
.header .col-xs-12 .title2 {
  font-size: 14px;
  font-weight: 300;
  color: white;
  display: block;
  text-align: center;
  font-family: lato, sans-serif;
}
.header .col-xs-12 loader-wrapper .logo {
  margin: 10px 0;
}
.login-back {
  float: left;
  padding: 12px 0;
  position: relative;
}
.login-back .fa-arrow-circle-o-left {
  font-size: 35px;
  color: #ff7b00;
}
.login-back span {
  position: absolute;
  top: 18px;
  left: 38px;
  color: #ff7b00;
  font-weight: bold;
}
.img-login {
  float: right;
  margin-top: 9px;
  margin-bottom: 10px;
  color: #ff7b00;
}
.content {
  background: #efefef;
  position: absolute;
  padding-top: 0;
  left: 0;
  width: 100%;
  margin-left: 0px;
}
.content .abcRioButton.abcRioButtonBlue {
  height: 40px !important;
  width: 100% !important;
  margin: 7px 0;
}
.content .abcRioButton.abcRioButtonBlue .abcRioButtonIcon {
  padding: 10px !important;
}
.content .abcRioButton.abcRioButtonBlue span.abcRioButtonContents {
  font-size: 16px !important;
  line-height: 37px !important;
}
.content .login {
  text-align: left;
  color: #333;
  font-size: 25px;
  font-weight: 900;
}
.content .text {
  color: #333;
  font-size: 15px;
}
.content form .label {
  color: #383838;
  padding: 0;
}
.content form .form-control {
  border-radius: 0 !important;
  border: 1px solid #a0a5b3;
  padding: 10px;
  height: 45px;
}
.content form .form-control:focus {
  outline: 0;
  border-color: #1ab188;
}
.content .line-container {
  display: block;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 10px;
  margin-bottom: 8px;
  overflow: hidden;
  text-align: center;
  white-space: nowrap;
  width: 90%;
  position: relative;
}
.content .line-container span:after,
.content .line-container span:before {
  background: #ced0d4;
  content: "";
  height: 1px;
  position: absolute;
  top: 50%;
  width: 9999px;
}
.content .line-container span:before {
  right: 55%;
}
.content .line-container span:after {
  left: 55%;
}
.content .gender {
  font-size: 10px;
  margin-top: 15px;
}
.modal-content {
  background: #efefef;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.modal-content .modal-header {
  font-weight: bold;
}
.modal-content .modal-body {
  color: white;
}
.modal-content .modal-body h1 {
  text-align: left;
  color: #000;
  font-size: 14px;
}
.modal-content .modal-body .row {
  margin-top: 10px;
}
.modal-content .modal-body .field-wrap .label-full {
  width: 100%;
}
.modal-content .modal-body .field-wrap .birthdate-full {
  width: 100%;
}
.modal-content .modal-body .field-wrap label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  color: #383838;
  font-weight: 700;
  padding-top: 9px;
}
.modal-content .modal-body .field-wrap .gender {
  line-height: 2.5;
}
.modal-content .modal-body .field-wrap input,
.modal-content .modal-body .field-wrap textarea {
  width: 100%;
  border-radius: 0 !important;
  border: 1px solid #a0a5b3;
}
.modal-content .modal-body .field-wrap input:focus,
.modal-content .modal-body .field-wrap textarea:focus {
  outline: 0;
  border-color: #1ab188;
}
.modal-content .modal-body .field-wrap .col-xs-8 {
  padding-left: 0;
}
.modal-content .modal-body .field-wrap .col-xs-4 {
  padding-right: 0;
}
.modal-content .modal-footer {
  border-top: 1px solid #ced0d4;
  padding: 7px;
}
.modal-content .modal-footer button {
  padding: 8px 30px;
  font-size: 13px;
}
.modal-content .modal-footer button#close-register-google {
  background: #1b2735;
}
.radio {
  margin-bottom: 5px;
}
.radio label.male {
  padding-left: 5px;
}
.radio span {
  margin-left: 10px !important;
}
.radio span label.female {
  margin-left: 5px !important;
  padding: 0 !important;
}
.birthdate {
  width: 100%;
  margin-top: 5px;
}
.birth-control {
  padding: 10px;
  font-size: 12px;
  margin-right: 1%;
  display: block;
  width: 100%;
  margin-bottom: 10px;
  height: 40px;
  border-radius: 0;
  outline: none;
  background: #ddd;
}
.birth-month {
  padding: 10px;
  font-size: 12px;
  margin-right: 1%;
  display: block;
  width: 100%;
  margin-bottom: 10px;
  height: 40px;
  border-radius: 0;
  outline: none;
  background: #ddd;
}
.birth-day {
  padding: 10px;
  font-size: 12px;
  margin-right: 1%;
  display: block;
  width: 100%;
  margin-bottom: 10px;
  height: 40px;
  border-radius: 0;
  outline: none;
  background: #ddd;
}
.birth-year {
  padding: 10px;
  font-size: 12px;
  margin-right: 1%;
  display: block;
  width: 100%;
  margin-bottom: 10px;
  height: 40px;
  border-radius: 0;
  outline: none;
  background: #ddd;
}
.button-back {
  margin-top: 0;
  font-size: 30px;
  background: 0 0;
  border: none;
  box-shadow: none;
  width: 100%;
  padding: 0;
  margin-right: 12px;
  overflow: hidden;
  border-radius: 0;
  line-height: 55px;
}
